import { RequireField } from "utils/common/types";

export type TiffTag = string[] | number[];
export type IFDTag = TiffTag | Uint8Array | IFD | number[][];

type Tags = {
  "254": string;
  "255": string;
  "256": string;
  "257": string;
  "258": string;
  "259": string;
  "262": string;
  "263": string;
  "264": string;
  "265": string;
  "266": string;
  "269": string;
  "270": string;
  "271": string;
  "272": string;
  "273": string;
  "274": string;
  "277": string;
  "278": string;
  "279": string;
  "280": string;
  "281": string;
  "282": string;
  "283": string;
  "284": string;
  "285": string;
  "286": string;
  "287": string;
  "288": string;
  "289": string;
  "290": string;
  "291": string;
  "292": string;
  "293": string;
  "296": string;
  "297": string;
  "300": string;
  "301": string;
  "305": string;
  "306": string;
  "315": string;
  "316": string;
  "317": string;
  "318": string;
  "319": string;
  "320": string;
  "321": string;
  "322": string;
  "323": string;
  "324": string;
  "325": string;
  "326": string;
  "327": string;
  "328": string;
  "330": string;
  "332": string;
  "333": string;
  "334": string;
  "336": string;
  "337": string;
  "338": string;
  "339": string;
  "340": string;
  "341": string;
  "343": string;
  "344": string;
  "345": string;
  "346": string;
  "347": string;
  "351": string;
  "400": string;
  "401": string;
  "402": string;
  "403": string;
  "404": string;
  "405": string;
  "433": string;
  "434": string;
  "435": string;
  "512": string;
  "513": string;
  "514": string;
  "515": string;
  "517": string;
  "518": string;
  "519": string;
  "520": string;
  "521": string;
  "529": string;
  "530": string;
  "531": string;
  "532": string;
  "559": string;
  "700": string;
  "32781": string;
  "32932": string;
  "32953": string;
  "32954": string;
  "32955": string;
  "32956": string;
  "32995": string;
  "32996": string;
  "32997": string;
  "32998": string;
  "33300": string;
  "33301": string;
  "33302": string;
  "33303": string;
  "33304": string;
  "33305": string;
  "33306": string;
  "33405": string;
  "33421": string;
  "33422": string;
  "33423": string;
  "33432": string;
  "33434": string;
  "33437": string;
  "33445": string;
  "33446": string;
  "33447": string;
  "33448": string;
  "33449": string;
  "33450": string;
  "33451": string;
  "33452": string;
  "33550": string;
  "33723": string;
  "33918": string;
  "33919": string;
  "33920": string;
  "33922": string;
  "34016": string;
  "34017": string;
  "34018": string;
  "34019": string;
  "34020": string;
  "34021": string;
  "34022": string;
  "34023": string;
  "34024": string;
  "34025": string;
  "34026": string;
  "34027": string;
  "34028": string;
  "34029": string;
  "34030": string;
  "34031": string;
  "34032": string;
  "34232": string;
  "34264": string;
  "34377": string;
  "34665": string;
  "34675": string;
  "34732": string;
  "34735": string;
  "34736": string;
  "34737": string;
  "34750": string;
  "34850": string;
  "34852": string;
  "34853": string;
  "34855": string;
  "34856": string;
  "34857": string;
  "34858": string;
  "34859": string;
  "34908": string;
  "34909": string;
  "34910": string;
  "34911": string;
  "34929": string;
  "36867": string;
  "37122": string;
  "37377": string;
  "37378": string;
  "37379": string;
  "37380": string;
  "37381": string;
  "37382": string;
  "37383": string;
  "37384": string;
  "37385": string;
  "37386": string;
  "37387": string;
  "37388": string;
  "37389": string;
  "37390": string;
  "37391": string;
  "37392": string;
  "37393": string;
  "37394": string;
  "37395": string;
  "37396": string;
  "37397": string;
  "37398": string;
  "37399": string;
  "37439": string;
  "37724": string;
  "40965": string;
  "42112": string;
  "42113": string;
  "50215": string;
  "50216": string;
  "50217": string;
  "50218": string;
  "50674": string;
  "50706": string;
  "50707": string;
  "50708": string;
  "50709": string;
  "50710": string;
  "50711": string;
  "50712": string;
  "50713": string;
  "50714": string;
  "50715": string;
  "50716": string;
  "50717": string;
  "50718": string;
  "50719": string;
  "50720": string;
  "50721": string;
  "50722": string;
  "50723": string;
  "50724": string;
  "50725": string;
  "50726": string;
  "50727": string;
  "50728": string;
  "50729": string;
  "50730": string;
  "50731": string;
  "50732": string;
  "50733": string;
  "50734": string;
  "50735": string;
  "50736": string;
  "50737": string;
  "50738": string;
  "50739": string;
  "50740": string;
  "50741": string;
  "50778": string;
  "50779": string;
  "50780": string;
  "50781": string;
  "50784": string;
  "50827": string;
  "50828": string;
  "50829": string;
  "50830": string;
  "50831": string;
  "50832": string;
  "50833": string;
  "50834": string;
  "50838": string;
  "50839": string;
  "50844": string;
  "50879": string;
  "50908": string;
  "50909": string;
  "50931": string;
  "50932": string;
  "50933": string;
  "50934": string;
  "50935": string;
  "50936": string;
  "50937": string;
  "50938": string;
  "50939": string;
  "50940": string;
  "50941": string;
  "50942": string;
  "50964": string;
  "50965": string;
  "50966": string;
  "50967": string;
  "50968": string;
  "50969": string;
  "50970": string;
  "50971": string;
  "50972": string;
  "50973": string;
  "50974": string;
  "50975": string;
  "50981": string;
  "50982": string;
  "51008": string;
  "51009": string;
  "51022": string;
  "51041": string;
  "51089": string;
  "51090": string;
  "51091": string;
  "51107": string;
  "51108": string;
  "51109": string;
  "51110": string;
  "51111": string;
  "51112": string;
  "51125": string;
  "51177": string;
  "51178": string;
  "51179": string;
  "51180": string;
  "51181": string;
  "51182": string;
  "52525": string;
  "52526": string;
  "52528": string;
  "52529": string;
  "52530": string;
  "52531": string;
  "52532": string;
  "52533": string;
  "52534": string;
  "52536": string;
  "52537": string;
  "52538": string;
  "52543": string;
  "53535": string;
  "55000": string;
  "55001": string;
  "55002": string;
  "55003": string;
  "55004": string;
  "65324": string;
  "65325": string;
  "65420": string;
  "65421": string;
  "65422": string;
  "65423": string;
  "65424": string;
  "65425": string;
  "65426": string;
  "65427": string;
  "65428": string;
  "65432": string;
  "65434": string;
  "65435": string;
  "65436": string;
  "65437": string;
  "65438": string;
  "65439": string;
  "65440": string;
  "65441": string;
  "65442": string;
  "65444": string;
  "65445": string;
  "65446": string;
  "65447": string;
  "65448": string;
  "65449": string;
  "65450": string;
  "65451": string;
  "65453": string;
  "65454": string;
  "65455": string;
  "65456": string;
  "65457": string;
  "65458": string;
  "65500": string;
  "65535": string;
  exifIFD: string;
  gpsiIFD: string;
};

export type IFD = {
  [property in keyof Tags]?: IFDTag;
};

export type BaseIFD = RequireField<
  IFD,
  | "254"
  | "256"
  | "257"
  | "258"
  | "259"
  | "262"
  | "273"
  | "277"
  | "278"
  | "279"
  | "282"
  | "283"
  | "296"
>;
export type PaletteColorIFD = RequireField<BaseIFD, "320">;
export type RGBIFD = RequireField<BaseIFD, "284">;
export type YCbCrIFD = RequireField<BaseIFD, "529" | "530" | "531" | "532">;
export type ClassFIFD = RequireField<BaseIFD, "326" | "327" | "328">;

// The number of bytes required to store the values
export const TIFF_OFFSETS: {
  header: { id: number; version: number; ifdo: number };
  ifd: { numFields: number; fieldSize: number; ifdo: number };
  field: {
    tagID: number;
    dataType: number;
    count: number;
    dataOffset: number;
  };
  tag: Record<string, number>;
} = {
  header: {
    id: 2,
    version: 2,
    ifdo: 4,
  },
  ifd: {
    numFields: 2,
    fieldSize: 12,
    ifdo: 4,
  },
  field: {
    tagID: 2,
    dataType: 2,
    count: 4,
    dataOffset: 4,
  },
  tag: {
    "254": 4,
    "255": 2,
    "256": 2,
    "257": 2,
    "258": 2,
    "259": 2,
    "262": 2,
    "266": 2,
    "269": 1,
    "270": 1,
    "271": 1,
    "272": 1,
    "273": 4,
    "274": 2,
    "277": 2,
    "278": 4,
    "279": 4,
    "280": 2,
    "281": 2,
    "282": 8,
    "283": 8,
    "284": 2,
    "285": 1,
    "286": 8,
    "287": 8,
    "292": 4,
    "293": 4,
    "296": 2,
    "297": 2,
    "305": 1,
    "306": 1,
    "315": 1,
    "316": 1,
    "317": 2,
    "318": 8,
    "319": 8,
    "320": 2,
    "322": 2,
    "323": 2,
    "324": 4,
    "325": 4,
    "326": 4,
    "327": 2,
    "328": 4,
    "338": 2,
    "339": 2,
    "347": 1,
    "513": 4,
    "514": 4,
    "530": 2,
    "531": 2,
    "700": 1,
    "532": 8,
    "33432": 1,
    "33434": 8,
    "33437": 8,
    "34665": 4,
    "34850": 2,
    "34853": 4,
    "34855": 2,
    "36867": 1,
    "37377": 8,
    "37378": 8,
    "37380": 8,
    "37381": 8,
    "37383": 2,
    "37384": 2,
    "37385": 2,
    "37386": 8,
    "40965": 4,
  },
};

const tag2Name: {
  "254": string;
  "255": string;
  "256": string;
  "257": string;
  "258": string;
  "259": string;
  "262": string;
  "263": string;
  "264": string;
  "265": string;
  "266": string;
  "269": string;
  "270": string;
  "271": string;
  "272": string;
  "273": string;
  "274": string;
  "277": string;
  "278": string;
  "279": string;
  "280": string;
  "281": string;
  "282": string;
  "283": string;
  "284": string;
  "285": string;
  "286": string;
  "287": string;
  "288": string;
  "289": string;
  "290": string;
  "291": string;
  "292": string;
  "293": string;
  "296": string;
  "297": string;
  "300": string;
  "301": string;
  "305": string;
  "306": string;
  "315": string;
  "316": string;
  "317": string;
  "318": string;
  "319": string;
  "320": string;
  "321": string;
  "322": string;
  "323": string;
  "324": string;
  "325": string;
  "326": string;
  "327": string;
  "328": string;
  "330": string;
  "332": string;
  "333": string;
  "334": string;
  "336": string;
  "337": string;
  "338": string;
  "339": string;
  "340": string;
  "341": string;
  "343": string;
  "344": string;
  "345": string;
  "346": string;
  "347": string;
  "351": string;
  "400": string;
  "401": string;
  "402": string;
  "403": string;
  "404": string;
  "405": string;
  "433": string;
  "434": string;
  "435": string;
  "512": string;
  "513": string;
  "514": string;
  "515": string;
  "517": string;
  "518": string;
  "519": string;
  "520": string;
  "521": string;
  "529": string;
  "530": string;
  "531": string;
  "532": string;
  "559": string;
  "700": string;
  "32781": string;
  "32932": string;
  "32953": string;
  "32954": string;
  "32955": string;
  "32956": string;
  "32995": string;
  "32996": string;
  "32997": string;
  "32998": string;
  "33300": string;
  "33301": string;
  "33302": string;
  "33303": string;
  "33304": string;
  "33305": string;
  "33306": string;
  "33405": string;
  "33421": string;
  "33422": string;
  "33423": string;
  "33432": string;
  "33434": string;
  "33437": string;
  "33445": string;
  "33446": string;
  "33447": string;
  "33448": string;
  "33449": string;
  "33450": string;
  "33451": string;
  "33452": string;
  "33550": string;
  "33723": string;
  "33918": string;
  "33919": string;
  "33920": string;
  "33922": string;
  "34016": string;
  "34017": string;
  "34018": string;
  "34019": string;
  "34020": string;
  "34021": string;
  "34022": string;
  "34023": string;
  "34024": string;
  "34025": string;
  "34026": string;
  "34027": string;
  "34028": string;
  "34029": string;
  "34030": string;
  "34031": string;
  "34032": string;
  "34232": string;
  "34264": string;
  "34377": string;
  "34665": string;
  "34675": string;
  "34732": string;
  "34735": string;
  "34736": string;
  "34737": string;
  "34750": string;
  "34850": string;
  "34852": string;
  "34853": string;
  "34855": string;
  "34856": string;
  "34857": string;
  "34858": string;
  "34859": string;
  "34908": string;
  "34909": string;
  "34910": string;
  "34911": string;
  "34929": string;
  "36867": string;
  "37122": string;
  "37377": string;
  "37378": string;
  "37379": string;
  "37380": string;
  "37381": string;
  "37382": string;
  "37383": string;
  "37384": string;
  "37385": string;
  "37386": string;
  "37387": string;
  "37388": string;
  "37389": string;
  "37390": string;
  "37391": string;
  "37392": string;
  "37393": string;
  "37394": string;
  "37395": string;
  "37396": string;
  "37397": string;
  "37398": string;
  "37399": string;
  "37439": string;
  "37724": string;
  "40965": string;
  "42112": string;
  "42113": string;
  "50215": string;
  "50216": string;
  "50217": string;
  "50218": string;
  "50674": string;
  "50706": string;
  "50707": string;
  "50708": string;
  "50709": string;
  "50710": string;
  "50711": string;
  "50712": string;
  "50713": string;
  "50714": string;
  "50715": string;
  "50716": string;
  "50717": string;
  "50718": string;
  "50719": string;
  "50720": string;
  "50721": string;
  "50722": string;
  "50723": string;
  "50724": string;
  "50725": string;
  "50726": string;
  "50727": string;
  "50728": string;
  "50729": string;
  "50730": string;
  "50731": string;
  "50732": string;
  "50733": string;
  "50734": string;
  "50735": string;
  "50736": string;
  "50737": string;
  "50738": string;
  "50739": string;
  "50740": string;
  "50741": string;
  "50778": string;
  "50779": string;
  "50780": string;
  "50781": string;
  "50784": string;
  "50827": string;
  "50828": string;
  "50829": string;
  "50830": string;
  "50831": string;
  "50832": string;
  "50833": string;
  "50834": string;
  "50838": string;
  "50839": string;
  "50844": string;
  "50879": string;
  "50908": string;
  "50909": string;
  "50931": string;
  "50932": string;
  "50933": string;
  "50934": string;
  "50935": string;
  "50936": string;
  "50937": string;
  "50938": string;
  "50939": string;
  "50940": string;
  "50941": string;
  "50942": string;
  "50964": string;
  "50965": string;
  "50966": string;
  "50967": string;
  "50968": string;
  "50969": string;
  "50970": string;
  "50971": string;
  "50972": string;
  "50973": string;
  "50974": string;
  "50975": string;
  "50981": string;
  "50982": string;
  "51008": string;
  "51009": string;
  "51022": string;
  "51041": string;
  "51089": string;
  "51090": string;
  "51091": string;
  "51107": string;
  "51108": string;
  "51109": string;
  "51110": string;
  "51111": string;
  "51112": string;
  "51125": string;
  "51177": string;
  "51178": string;
  "51179": string;
  "51180": string;
  "51181": string;
  "51182": string;
  "52525": string;
  "52526": string;
  "52528": string;
  "52529": string;
  "52530": string;
  "52531": string;
  "52532": string;
  "52533": string;
  "52534": string;
  "52536": string;
  "52537": string;
  "52538": string;
  "52543": string;
  "53535": string;
  "55000": string;
  "55001": string;
  "55002": string;
  "55003": string;
  "55004": string;
  "65324": string;
  "65325": string;
  "65420": string;
  "65421": string;
  "65422": string;
  "65423": string;
  "65424": string;
  "65425": string;
  "65426": string;
  "65427": string;
  "65428": string;
  "65432": string;
  "65434": string;
  "65435": string;
  "65436": string;
  "65437": string;
  "65438": string;
  "65439": string;
  "65440": string;
  "65441": string;
  "65442": string;
  "65444": string;
  "65445": string;
  "65446": string;
  "65447": string;
  "65448": string;
  "65449": string;
  "65450": string;
  "65451": string;
  "65453": string;
  "65454": string;
  "65455": string;
  "65456": string;
  "65457": string;
  "65458": string;
  "65500": string;
  "65535": string;
} = {
  "254": "NewSubfileType",
  "255": "OldSubfileType",
  "256": "ImageWidth",
  "257": "ImageLength",
  "258": "BitsPerSample",
  "259": "Compression",
  "262": "Photometric",
  "263": "Threshholding",
  "264": "CellWidth",
  "265": "CellLength",
  "266": "FillOrder",
  "269": "DocumentName",
  "270": "ImageDescription",
  "271": "Make",
  "272": "Model",
  "273": "StripOffsets",
  "274": "Orientation",
  "277": "SamplesPerPixel",
  "278": "RowsPerStrip",
  "279": "StripByteCounts",
  "280": "MinSampleValue",
  "281": "MaxSampleValue",
  "282": "XResolution",
  "283": "YResolution",
  "284": "PlanarConfig",
  "285": "PageName",
  "286": "Xposition",
  "287": "Yposition",
  "288": "FreeOffsets",
  "289": "FreeByteCounts",
  "290": "GrayResponseUnit",
  "291": "GrayResponseCurve",
  "292": "T4Options",
  "293": "T6Options",
  "296": "ResolutionUnit",
  "297": "PageNumber",
  "300": "ColorResponseUnit",
  "301": "TransferFunction",
  "305": "Software",
  "306": "DateTime",
  "315": "Artist",
  "316": "HostComputer",
  "317": "Predictor",
  "318": "WhitePoint",
  "319": "PrimaryChromaticities",
  "320": "ColorMap",
  "321": "HalftoneHints",
  "322": "TileWidth",
  "323": "TileLength",
  "324": "TileOffsets",
  "325": "TileByteCounts",
  "326": "BadFaxLines",
  "327": "CleanFaxData",
  "328": "ConsecutiveBadFaxLines",
  "330": "SubIFD",
  "332": "InkSet",
  "333": "InkNames",
  "334": "NumberOfInks",
  "336": "DotRange",
  "337": "TargetPrinter",
  "338": "ExtraSamples",
  "339": "SampleFormat",
  "340": "SMinSampleValue",
  "341": "SMaxSampleValue",
  "343": "ClipPath",
  "344": "XClipPathUnits",
  "345": "YClipPathUnits",
  "346": "Indexed",
  "347": "JPEGTables",
  "351": "OpiProxy",
  "400": "GlobalParametersIFD",
  "401": "ProfileType",
  "402": "FaxProfile",
  "403": "CodingMethods",
  "404": "VersionYear",
  "405": "ModeNumber",
  "433": "Decode",
  "434": "ImageBaseColor",
  "435": "T82Options",
  "512": "JPEGProc",
  "513": "JPEGIFOffset",
  "514": "JPEGIFByteCount",
  "515": "JPEGRestartInterval",
  "517": "JPEGLosslessPredictors",
  "518": "JPEGPointTransform",
  "519": "JPEGQTables",
  "520": "JPEGDCTables",
  "521": "JPEGACTables",
  "529": "YCbCrCoefficients",
  "530": "YCbCrSubsampling",
  "531": "YCbCrPositioning",
  "532": "ReferenceBlackWhite",
  "559": "StripRowCounts",
  "700": "XMLPacket",
  "32781": "OPIImageID",
  "32932": "WangAnnotation",
  "32953": "RefPts",
  "32954": "RegionTackPoint",
  "32955": "RegionWarpCorners",
  "32956": "RegionAffine",
  "32995": "Matteing",
  "32996": "Datatype",
  "32997": "ImageDepth",
  "32998": "TileDepth",
  "33300": "PIXAR_ImageFullWidth",
  "33301": "PIXAR_ImageFullLength",
  "33302": "PIXAR_TextureFormat",
  "33303": "PIXAR_WrapModes",
  "33304": "PIXAR_FovCot",
  "33305": "PIXAR_Matrix_WorldToScreen",
  "33306": "PIXAR_Matrix_WorldToCamera",
  "33405": "WriterSerialNumber",
  "33421": "CFARepeatPatternDim",
  "33422": "CFAPattern",
  "33423": "BatteryLevel",
  "33432": "Copyright",
  "33434": "EXPOSURETIME",
  "33437": "FNUMBER",
  "33445": "MDFileTag",
  "33446": "MDScalePixel",
  "33447": "MDColorTable",
  "33448": "MDLabName",
  "33449": "MDSampleInfo",
  "33450": "MDPrepDate",
  "33451": "MDPrepTime",
  "33452": "MDFileUnits",
  "33550": "ModelPixelScaleTag",
  "33723": "IPTC_NAA",
  "33918": "INGRPacketDataTag",
  "33919": "INGRFlagRegisters",
  "33920": "IrasBTransformationMatrix",
  "33922": "ModelTiepointTag",
  "34016": "IT8Site",
  "34017": "IT8ColorSequence",
  "34018": "IT8Header",
  "34019": "IT8RasterPadding",
  "34020": "IT8BitsPerRunLength",
  "34021": "IT8BitsPerExtendedRunLength",
  "34022": "IT8ColorTable",
  "34023": "IT8ImageColorIndicator",
  "34024": "IT8BkgColorIndicator",
  "34025": "IT8ImageColorValue",
  "34026": "IT8BkgColorValue",
  "34027": "IT8PixelIntensityRange",
  "34028": "IT8TransparencyIndicator",
  "34029": "IT8ColorCharacterization",
  "34030": "IT8HCUsage",
  "34031": "IT8TrapIndicator",
  "34032": "IT8CMYKEquivalent",
  "34232": "FrameCount",
  "34264": "ModelTransformationTag",
  "34377": "Photoshop",
  "34665": "EXIFIFD",
  "34675": "ICCProfile",
  "34732": "ImageLayer",
  "34735": "GeoKeyDirectoryTag",
  "34736": "GeoDoubleParamsTag",
  "34737": "GeoAsciiParamsTag",
  "34750": "JBIGOptions",
  "34850": "EXPOSUREPROGRAM",
  "34852": "SPECTRALSENSITIVITY",
  "34853": "GPSIFD",
  "34855": "ISOSPEEDRATINGS",
  "34856": "OECF",
  "34857": "INTERLACE",
  "34858": "TIMEZONEOFFSET",
  "34859": "SELFTIMERMODE",
  "34908": "FaxRecvParams",
  "34909": "FaxSubaddress",
  "34910": "FaxRecvTime",
  "34911": "FAXDCS",
  "34929": "FEDEX_EDR",
  "36867": "DATETIMEORIGINAL",
  "37122": "COMPRESSEDBITSPERPIXEL",
  "37377": "SHUTTERSPEEDVALUE",
  "37378": "APERTUREVALUE",
  "37379": "BRIGHTNESSVALUE",
  "37380": "EXPOSUREBIASVALUE",
  "37381": "MAXAPERTUREVALUE",
  "37382": "SUBJECTDISTANCE",
  "37383": "METERINGMODE",
  "37384": "LIGHTSOURCE",
  "37385": "FLASH",
  "37386": "FOCALLENGTH",
  "37387": "FLASHENERGY",
  "37388": "SPATIALFREQUENCYRESPONSE",
  "37389": "NOISE",
  "37390": "FOCALPLANEXRESOLUTION",
  "37391": "FOCALPLANEYRESOLUTION",
  "37392": "FOCALPLANERESOLUTIONUNIT",
  "37393": "IMAGENUMBER",
  "37394": "SECURITYCLASSIFICATION",
  "37395": "IMAGEHISTORY",
  "37396": "SUBJECTLOCATION",
  "37397": "EXPOSUREINDEX",
  "37398": "STANDARDID",
  "37399": "SENSINGMETHOD",
  "37439": "StoNits",
  "37724": "ImageSourceData",
  "40965": "InteroperabilityIFD",
  "42112": "GDAL_Metadata",
  "42113": "GDAL_NoData",
  "50215": "OceScanjobDescription",
  "50216": "OceApplicationSelector",
  "50217": "OceIdentificationNumber",
  "50218": "OceImageLogicCharacteristics",
  "50674": "LERC_PARAMETERS",
  "50706": "DNGVersion",
  "50707": "DNGBackwardVersion",
  "50708": "UniqueCameraModel",
  "50709": "LocalizedCameraModel",
  "50710": "CFAPlaneColor",
  "50711": "CFALayout",
  "50712": "LinearizationTable",
  "50713": "BlackLevelRepeatDim",
  "50714": "BlackLevel",
  "50715": "BlackLevelDeltaH",
  "50716": "BlackLevelDeltaV",
  "50717": "WhiteLevel",
  "50718": "DefaultScale",
  "50719": "DefaultCropOrigin",
  "50720": "DefaultCropSize",
  "50721": "ColorMatrix1",
  "50722": "ColorMatrix2",
  "50723": "CameraCalibration1",
  "50724": "CameraCalibration2",
  "50725": "ReductionMatrix1",
  "50726": "ReductionMatrix2",
  "50727": "AnalogBalance",
  "50728": "AsShotNeutral",
  "50729": "AsShotWhiteXY",
  "50730": "BaselineExposure",
  "50731": "BaselineNoise",
  "50732": "BaselineSharpness",
  "50733": "BayerGreenSplit",
  "50734": "LinearResponseLimit",
  "50735": "CameraSerialNumber",
  "50736": "LensInfo",
  "50737": "ChromaBlurRadius",
  "50738": "AntiAliasStrength",
  "50739": "ShadowScale",
  "50740": "DNGPrivateData",
  "50741": "MakerNoteSafety",
  "50778": "CalibrationIlluminant1",
  "50779": "CalibrationIlluminant2",
  "50780": "BestQualityScale",
  "50781": "RAWDATAUNIQUEID",
  "50784": "AliasLayerMetadata",
  "50827": "ORIGINALRAWFILENAME",
  "50828": "ORIGINALRAWFILEDATA",
  "50829": "ACTIVEAREA",
  "50830": "MASKEDAREAS",
  "50831": "ASSHOTICCPROFILE",
  "50832": "ASSHOTPREPROFILEMATRIX",
  "50833": "CURRENTICCPROFILE",
  "50834": "CURRENTPREPROFILEMATRIX",
  "50838": "ImageJMetadataByteCounts",
  "50839": "ImageJMetadata",
  "50844": "RPCCOEFFICIENT",
  "50879": "COLORIMETRICREFERENCE",
  "50908": "TIFF_RSID",
  "50909": "GEO_METADATA",
  "50931": "CAMERACALIBRATIONSIGNATURE",
  "50932": "PROFILECALIBRATIONSIGNATURE",
  "50933": "TIFFTAG_EXTRACAMERAPROFILES",
  "50934": "ASSHOTPROFILENAME",
  "50935": "NOISEREDUCTIONAPPLIED",
  "50936": "PROFILENAME",
  "50937": "PROFILEHUESATMAPDIMS",
  "50938": "PROFILEHUESATMAPDATA1",
  "50939": "PROFILEHUESATMAPDATA2",
  "50940": "PROFILETONECURVE",
  "50941": "PROFILEEMBEDPOLICY",
  "50942": "PROFILECOPYRIGHT",
  "50964": "FORWARDMATRIX1",
  "50965": "FORWARDMATRIX2",
  "50966": "PREVIEWAPPLICATIONNAME",
  "50967": "PREVIEWAPPLICATIONVERSION",
  "50968": "PREVIEWSETTINGSNAME",
  "50969": "PREVIEWSETTINGSDIGEST",
  "50970": "PREVIEWCOLORSPACE",
  "50971": "PREVIEWDATETIME",
  "50972": "RAWIMAGEDIGEST",
  "50973": "ORIGINALRAWFILEDIGEST",
  "50974": "SUBTILEBLOCKSIZE",
  "50975": "ROWINTERLEAVEFACTOR",
  "50981": "PROFILELOOKTABLEDIMS",
  "50982": "PROFILELOOKTABLEDATA",
  "51008": "OPCODELIST1",
  "51009": "OPCODELIST2",
  "51022": "OPCODELIST3",
  "51041": "NOISEPROFILE",
  "51089": "ORIGINALDEFAULTFINALSIZE",
  "51090": "ORIGINALBESTQUALITYFINALSIZE",
  "51091": "ORIGINALDEFAULTCROPSIZE",
  "51107": "PROFILEHUESATMAPENCODING",
  "51108": "PROFILELOOKTABLEENCODING",
  "51109": "BASELINEEXPOSUREOFFSET",
  "51110": "DEFAULTBLACKRENDER",
  "51111": "NEWRAWIMAGEDIGEST",
  "51112": "RAWTOPREVIEWGAIN",
  "51125": "DEFAULTUSERCROP",
  "51177": "DEPTHFORMAT",
  "51178": "DEPTHNEAR",
  "51179": "DEPTHFAR",
  "51180": "DEPTHUNITS",
  "51181": "DEPTHMEASURETYPE",
  "51182": "ENHANCEPARAMS",
  "52525": "PROFILEGAINTABLEMAP",
  "52526": "SEMANTICNAME",
  "52528": "SEMANTICINSTANCEID",
  "52529": "CALIBRATIONILLUMINANT3",
  "52530": "CAMERACALIBRATION3",
  "52531": "COLORMATRIX3",
  "52532": "FORWARDMATRIX3",
  "52533": "ILLUMINANTDATA1",
  "52534": "ILLUMINANTDATA2",
  "52536": "MASKSUBAREA",
  "52537": "PROFILEHUESATMAPDATA3",
  "52538": "REDUCTIONMATRIX3",
  "52543": "RGBTABLES",
  "53535": "ILLUMINANTDATA3",
  "55000": "AperioUnknown55000",
  "55001": "AperioMagnification",
  "55002": "AperioMPP",
  "55003": "AperioScanScopeID",
  "55004": "AperioDate",
  "65324": "NDPI_OffsetHighBytes",
  "65325": "NDPI_ByteCountHighBytes",
  "65420": "NDPI_FORMAT_FLAG",
  "65421": "NDPI_SOURCELENS",
  "65422": "NDPI_XOFFSET",
  "65423": "NDPI_YOFFSET",
  "65424": "NDPI_FOCAL_PLANE",
  "65425": "NDPI_TissueIndex",
  "65426": "NDPI_MCU_STARTS",
  "65427": "NDPI_REFERENCE",
  "65428": "NDPI_AuthCode",
  "65432": "NDPI_McuStartsHighBytes",
  "65434": "NDPI_CHANNEL",
  "65435": "NDPI_ExposureRatio",
  "65436": "NDPI_RedMultiplier",
  "65437": "NDPI_GreenMultiplier",
  "65438": "NDPI_BlueMultiplier",
  "65439": "NDPI_FocusPoints",
  "65440": "NDPI_FocusPointRegions",
  "65441": "NDPI_CaptureMode",
  "65442": "NDPI_NDPSN",
  "65444": "NDPI_JpegQuality",
  "65445": "NDPI_RefocusInterval",
  "65446": "NDPI_FocusOffset",
  "65447": "NDPI_BlankLines",
  "65448": "NDPI_FirmwareVersion",
  "65449": "NDPI_PROPERTY_MAP",
  "65450": "NDPI_LabelObscured",
  "65451": "NDPI_EMISSION_WAVELENGTH",
  "65453": "NDPI_LampAge",
  "65454": "NDPI_ExposureTime",
  "65455": "NDPI_FocusTime",
  "65456": "NDPI_ScanTime",
  "65457": "NDPI_WriteTime",
  "65458": "NDPI_FullyAutoFocus",
  "65500": "NDPI_DefaultGamma",
  "65535": "DCSHUESHIFTVALUES",
};

const name2Tag: Record<string, string> = {
  NewSubfileType: "254",
  OldSubfileType: "255",
  ImageWidth: "256",
  ImageLength: "257",
  BitsPerSample: "258",
  Compression: "259",
  Photometric: "262",
  Threshholding: "263",
  CellWidth: "264",
  CellLength: "265",
  FillOrder: "266",
  DocumentName: "269",
  ImageDescription: "270",
  Make: "271",
  Model: "272",
  StripOffsets: "273",
  Orientation: "274",
  SamplesPerPixel: "277",
  RowsPerStrip: "278",
  StripByteCounts: "279",
  MinSampleValue: "280",
  MaxSampleValue: "281",
  XResolution: "282",
  YResolution: "283",
  PlanarConfig: "284",
  PageName: "285",
  Xposition: "286",
  Yposition: "287",
  FreeOffsets: "288",
  FreeByteCounts: "289",
  GrayResponseUnit: "290",
  GrayResponseCurve: "291",
  T4Options: "292",
  T6Options: "293",
  ResolutionUnit: "296",
  PageNumber: "297",
  ColorResponseUnit: "300",
  TransferFunction: "301",
  Software: "305",
  DateTime: "306",
  Artist: "315",
  HostComputer: "316",
  Predictor: "317",
  WhitePoint: "318",
  PrimaryChromaticities: "319",
  ColorMap: "320",
  HalftoneHints: "321",
  TileWidth: "322",
  TileLength: "323",
  TileOffsets: "324",
  TileByteCounts: "325",
  BadFaxLines: "326",
  CleanFaxData: "327",
  ConsecutiveBadFaxLines: "328",
  SubIFD: "330",
  InkSet: "332",
  InkNames: "333",
  NumberOfInks: "334",
  DotRange: "336",
  TargetPrinter: "337",
  ExtraSamples: "338",
  SampleFormat: "339",
  SMinSampleValue: "340",
  SMaxSampleValue: "341",
  ClipPath: "343",
  XClipPathUnits: "344",
  YClipPathUnits: "345",
  Indexed: "346",
  JPEGTables: "347",
  OpiProxy: "351",
  GlobalParametersIFD: "400",
  ProfileType: "401",
  FaxProfile: "402",
  CodingMethods: "403",
  VersionYear: "404",
  ModeNumber: "405",
  Decode: "433",
  ImageBaseColor: "434",
  T82Options: "435",
  JPEGProc: "512",
  JPEGIFOffset: "513",
  JPEGIFByteCount: "514",
  JPEGRestartInterval: "515",
  JPEGLosslessPredictors: "517",
  JPEGPointTransform: "518",
  JPEGQTables: "519",
  JPEGDCTables: "520",
  JPEGACTables: "521",
  YCbCrCoefficients: "529",
  YCbCrSubsampling: "530",
  YCbCrPositioning: "531",
  ReferenceBlackWhite: "532",
  StripRowCounts: "559",
  XMLPacket: "700",
  OPIImageID: "32781",
  WangAnnotation: "32932",
  RefPts: "32953",
  RegionTackPoint: "32954",
  RegionWarpCorners: "32955",
  RegionAffine: "32956",
  Matteing: "32995",
  Datatype: "32996",
  ImageDepth: "32997",
  TileDepth: "32998",
  PIXAR_ImageFullWidth: "33300",
  PIXAR_ImageFullLength: "33301",
  PIXAR_TextureFormat: "33302",
  PIXAR_WrapModes: "33303",
  PIXAR_FovCot: "33304",
  PIXAR_Matrix_WorldToScreen: "33305",
  PIXAR_Matrix_WorldToCamera: "33306",
  WriterSerialNumber: "33405",
  CFARepeatPatternDim: "33421",
  CFAPattern: "33422",
  BatteryLevel: "33423",
  Copyright: "33432",
  EXPOSURETIME: "33434",
  FNUMBER: "33437",
  MDFileTag: "33445",
  MDScalePixel: "33446",
  MDColorTable: "33447",
  MDLabName: "33448",
  MDSampleInfo: "33449",
  MDPrepDate: "33450",
  MDPrepTime: "33451",
  MDFileUnits: "33452",
  ModelPixelScaleTag: "33550",
  IPTC_NAA: "33723",
  INGRPacketDataTag: "33918",
  INGRFlagRegisters: "33919",
  IrasBTransformationMatrix: "33920",
  ModelTiepointTag: "33922",
  IT8Site: "34016",
  IT8ColorSequence: "34017",
  IT8Header: "34018",
  IT8RasterPadding: "34019",
  IT8BitsPerRunLength: "34020",
  IT8BitsPerExtendedRunLength: "34021",
  IT8ColorTable: "34022",
  IT8ImageColorIndicator: "34023",
  IT8BkgColorIndicator: "34024",
  IT8ImageColorValue: "34025",
  IT8BkgColorValue: "34026",
  IT8PixelIntensityRange: "34027",
  IT8TransparencyIndicator: "34028",
  IT8ColorCharacterization: "34029",
  IT8HCUsage: "34030",
  IT8TrapIndicator: "34031",
  IT8CMYKEquivalent: "34032",
  FrameCount: "34232",
  ModelTransformationTag: "34264",
  Photoshop: "34377",
  EXIFIFD: "34665",
  ICCProfile: "34675",
  ImageLayer: "34732",
  GeoKeyDirectoryTag: "34735",
  GeoDoubleParamsTag: "34736",
  GeoAsciiParamsTag: "34737",
  JBIGOptions: "34750",
  EXPOSUREPROGRAM: "34850",
  SPECTRALSENSITIVITY: "34852",
  GPSIFD: "34853",
  ISOSPEEDRATINGS: "34855",
  OECF: "34856",
  INTERLACE: "34857",
  TIMEZONEOFFSET: "34858",
  SELFTIMERMODE: "34859",
  FaxRecvParams: "34908",
  FaxSubaddress: "34909",
  FaxRecvTime: "34910",
  FAXDCS: "34911",
  FEDEX_EDR: "34929",
  DATETIMEORIGINAL: "36867",
  COMPRESSEDBITSPERPIXEL: "37122",
  SHUTTERSPEEDVALUE: "37377",
  APERTUREVALUE: "37378",
  BRIGHTNESSVALUE: "37379",
  EXPOSUREBIASVALUE: "37380",
  MAXAPERTUREVALUE: "37381",
  SUBJECTDISTANCE: "37382",
  METERINGMODE: "37383",
  LIGHTSOURCE: "37384",
  FLASH: "37385",
  FOCALLENGTH: "37386",
  FLASHENERGY: "37387",
  SPATIALFREQUENCYRESPONSE: "37388",
  NOISE: "37389",
  FOCALPLANEXRESOLUTION: "37390",
  FOCALPLANEYRESOLUTION: "37391",
  FOCALPLANERESOLUTIONUNIT: "37392",
  IMAGENUMBER: "37393",
  SECURITYCLASSIFICATION: "37394",
  IMAGEHISTORY: "37395",
  SUBJECTLOCATION: "37396",
  EXPOSUREINDEX: "37397",
  STANDARDID: "37398",
  SENSINGMETHOD: "37399",
  StoNits: "37439",
  ImageSourceData: "37724",
  InteroperabilityIFD: "40965",
  GDAL_Metadata: "42112",
  GDAL_NoData: "42113",
  OceScanjobDescription: "50215",
  OceApplicationSelector: "50216",
  OceIdentificationNumber: "50217",
  OceImageLogicCharacteristics: "50218",
  LERC_PARAMETERS: "50674",
  DNGVersion: "50706",
  DNGBackwardVersion: "50707",
  UniqueCameraModel: "50708",
  LocalizedCameraModel: "50709",
  CFAPlaneColor: "50710",
  CFALayout: "50711",
  LinearizationTable: "50712",
  BlackLevelRepeatDim: "50713",
  BlackLevel: "50714",
  BlackLevelDeltaH: "50715",
  BlackLevelDeltaV: "50716",
  WhiteLevel: "50717",
  DefaultScale: "50718",
  DefaultCropOrigin: "50719",
  DefaultCropSize: "50720",
  ColorMatrix1: "50721",
  ColorMatrix2: "50722",
  CameraCalibration1: "50723",
  CameraCalibration2: "50724",
  ReductionMatrix1: "50725",
  ReductionMatrix2: "50726",
  AnalogBalance: "50727",
  AsShotNeutral: "50728",
  AsShotWhiteXY: "50729",
  BaselineExposure: "50730",
  BaselineNoise: "50731",
  BaselineSharpness: "50732",
  BayerGreenSplit: "50733",
  LinearResponseLimit: "50734",
  CameraSerialNumber: "50735",
  LensInfo: "50736",
  ChromaBlurRadius: "50737",
  AntiAliasStrength: "50738",
  ShadowScale: "50739",
  DNGPrivateData: "50740",
  MakerNoteSafety: "50741",
  CalibrationIlluminant1: "50778",
  CalibrationIlluminant2: "50779",
  BestQualityScale: "50780",
  RAWDATAUNIQUEID: "50781",
  AliasLayerMetadata: "50784",
  ORIGINALRAWFILENAME: "50827",
  ORIGINALRAWFILEDATA: "50828",
  ACTIVEAREA: "50829",
  MASKEDAREAS: "50830",
  ASSHOTICCPROFILE: "50831",
  ASSHOTPREPROFILEMATRIX: "50832",
  CURRENTICCPROFILE: "50833",
  CURRENTPREPROFILEMATRIX: "50834",
  ImageJMetadataByteCounts: "50838",
  ImageJMetadata: "50839",
  RPCCOEFFICIENT: "50844",
  COLORIMETRICREFERENCE: "50879",
  TIFF_RSID: "50908",
  GEO_METADATA: "50909",
  CAMERACALIBRATIONSIGNATURE: "50931",
  PROFILECALIBRATIONSIGNATURE: "50932",
  TIFFTAG_EXTRACAMERAPROFILES: "50933",
  ASSHOTPROFILENAME: "50934",
  NOISEREDUCTIONAPPLIED: "50935",
  PROFILENAME: "50936",
  PROFILEHUESATMAPDIMS: "50937",
  PROFILEHUESATMAPDATA1: "50938",
  PROFILEHUESATMAPDATA2: "50939",
  PROFILETONECURVE: "50940",
  PROFILEEMBEDPOLICY: "50941",
  PROFILECOPYRIGHT: "50942",
  FORWARDMATRIX1: "50964",
  FORWARDMATRIX2: "50965",
  PREVIEWAPPLICATIONNAME: "50966",
  PREVIEWAPPLICATIONVERSION: "50967",
  PREVIEWSETTINGSNAME: "50968",
  PREVIEWSETTINGSDIGEST: "50969",
  PREVIEWCOLORSPACE: "50970",
  PREVIEWDATETIME: "50971",
  RAWIMAGEDIGEST: "50972",
  ORIGINALRAWFILEDIGEST: "50973",
  SUBTILEBLOCKSIZE: "50974",
  ROWINTERLEAVEFACTOR: "50975",
  PROFILELOOKTABLEDIMS: "50981",
  PROFILELOOKTABLEDATA: "50982",
  OPCODELIST1: "51008",
  OPCODELIST2: "51009",
  OPCODELIST3: "51022",
  NOISEPROFILE: "51041",
  ORIGINALDEFAULTFINALSIZE: "51089",
  ORIGINALBESTQUALITYFINALSIZE: "51090",
  ORIGINALDEFAULTCROPSIZE: "51091",
  PROFILEHUESATMAPENCODING: "51107",
  PROFILELOOKTABLEENCODING: "51108",
  BASELINEEXPOSUREOFFSET: "51109",
  DEFAULTBLACKRENDER: "51110",
  NEWRAWIMAGEDIGEST: "51111",
  RAWTOPREVIEWGAIN: "51112",
  DEFAULTUSERCROP: "51125",
  DEPTHFORMAT: "51177",
  DEPTHNEAR: "51178",
  DEPTHFAR: "51179",
  DEPTHUNITS: "51180",
  DEPTHMEASURETYPE: "51181",
  ENHANCEPARAMS: "51182",
  PROFILEGAINTABLEMAP: "52525",
  SEMANTICNAME: "52526",
  SEMANTICINSTANCEID: "52528",
  CALIBRATIONILLUMINANT3: "52529",
  CAMERACALIBRATION3: "52530",
  COLORMATRIX3: "52531",
  FORWARDMATRIX3: "52532",
  ILLUMINANTDATA1: "52533",
  ILLUMINANTDATA2: "52534",
  MASKSUBAREA: "52536",
  PROFILEHUESATMAPDATA3: "52537",
  REDUCTIONMATRIX3: "52538",
  RGBTABLES: "52543",
  ILLUMINANTDATA3: "53535",
  AperioUnknown55000: "55000",
  AperioMagnification: "55001",
  AperioMPP: "55002",
  AperioScanScopeID: "55003",
  AperioDate: "55004",
  NDPI_OffsetHighBytes: "65324",
  NDPI_ByteCountHighBytes: "65325",
  NDPI_FORMAT_FLAG: "65420",
  NDPI_SOURCELENS: "65421",
  NDPI_XOFFSET: "65422",
  NDPI_YOFFSET: "65423",
  NDPI_FOCAL_PLANE: "65424",
  NDPI_TissueIndex: "65425",
  NDPI_MCU_STARTS: "65426",
  NDPI_REFERENCE: "65427",
  NDPI_AuthCode: "65428",
  NDPI_McuStartsHighBytes: "65432",
  NDPI_CHANNEL: "65434",
  NDPI_ExposureRatio: "65435",
  NDPI_RedMultiplier: "65436",
  NDPI_GreenMultiplier: "65437",
  NDPI_BlueMultiplier: "65438",
  NDPI_FocusPoints: "65439",
  NDPI_FocusPointRegions: "65440",
  NDPI_CaptureMode: "65441",
  NDPI_NDPSN: "65442",
  NDPI_JpegQuality: "65444",
  NDPI_RefocusInterval: "65445",
  NDPI_FocusOffset: "65446",
  NDPI_BlankLines: "65447",
  NDPI_FirmwareVersion: "65448",
  NDPI_PROPERTY_MAP: "65449",
  NDPI_LabelObscured: "65450",
  NDPI_EMISSION_WAVELENGTH: "65451",
  NDPI_LampAge: "65453",
  NDPI_ExposureTime: "65454",
  NDPI_FocusTime: "65455",
  NDPI_ScanTime: "65456",
  NDPI_WriteTime: "65457",
  NDPI_FullyAutoFocus: "65458",
  NDPI_DefaultGamma: "65500",
  DCSHUESHIFTVALUES: "65535",
};
export const tagLookup: Record<string | number, string | number> = {
  ...tag2Name,
  ...name2Tag,
};

/* Maps the IFD tag to the value type:
    1 - BYTE
    2 - ASCII
    3 - SHORT
    4 - LONG
    5 - RATIONAL
    6 - SBYTE
    7 - UNDEFINE
    8 - SSHORT
    9 - SLONG
    10 - SRATIONAL
    11 - FLOAT
    12 - DOUBLE
 */
export const tagType: {
  basic: { main: number[]; rest: Record<number, number> };
  gps: { main: number[]; rest: Record<number, number> };
} = (() => {
  // tags start at 250
  var main: number[] = new Array(250);
  main.fill(0);
  main = main.concat([
    0, 0, 0, 0, 4, 3, 3, 3, 3, 3, 0, 0, 3, 0, 0, 0, 3, 0, 0, 2, 2, 2, 2, 4, 3,
    0, 0, 3, 4, 4, 3, 3, 5, 5, 3, 2, 5, 5, 0, 0, 0, 0, 4, 4, 0, 0, 3, 3, 0, 0,
    0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 3, 5, 5, 3, 0, 3, 3, 4,
    4, 4, 3, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 3, 3, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  var rest = {
    33432: 2,
    33434: 5,
    33437: 5,
    34665: 4,
    34850: 3,
    34853: 4,
    34855: 3,
    34864: 3,
    34866: 4,
    36864: 7,
    36867: 2,
    36868: 2,
    37121: 7,
    37377: 10,
    37378: 5,
    37380: 10,
    37381: 5,
    37383: 3,
    37384: 3,
    37385: 3,
    37386: 5,
    37510: 7,
    37520: 2,
    37521: 2,
    37522: 2,
    40960: 7,
    40961: 3,
    40962: 4,
    40963: 4,
    40965: 4,
    41486: 5,
    41487: 5,
    41488: 3,
    41985: 3,
    41986: 3,
    41987: 3,
    41988: 5,
    41989: 3,
    41990: 3,
    41993: 3,
    41994: 3,
    41995: 7,
    41996: 3,
    42032: 2,
    42033: 2,
    42034: 5,
    42036: 2,
    42037: 2,
    59932: 7,
  };
  return {
    basic: {
      main: main,
      rest: rest,
    },
    gps: {
      main: [1, 2, 5, 2, 5, 1, 5, 5, 0, 9],
      rest: { 18: 2, 29: 2 },
    },
  };
})();
